/* PAYMENT TERM */
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD"
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS"
export const GET_PAYMENT_METHOD_FAIL = "GET_PAYMENT_METHOD_FAIL"

export const ADD_NEW_PAYMENT_METHOD_LIST = "ADD_NEW_PAYMENT_METHOD_LIST"
export const ADD_NEW_PAYMENT_METHOD_LIST_SUCCESS = "ADD_NEW_PAYMENT_METHOD_LIST_SUCCESS"
export const ADD_NEW_PAYMENT_METHOD_LIST_FAIL = "ADD_NEW_PAYMENT_METHOD_LIST_FAIL"

export const UPDATE_PAYMENT_METHOD_LIST = "UPDATE_PAYMENT_METHOD_LIST"
export const UPDATE_PAYMENT_METHOD_LIST_SUCCESS = "UPDATE_PAYMENT_METHOD_LIST_SUCCESS"
export const UPDATE_PAYMENT_METHOD_LIST_FAIL = "UPDATE_PAYMENT_METHOD_LIST_FAIL"

export const DELETE_PAYMENT_METHOD_LIST = "DELETE_PAYMENT_METHOD_LIST"
export const DELETE_PAYMENT_METHOD_LIST_SUCCESS = "DELETE_PAYMENT_METHOD_LIST_SUCCESS"
export const DELETE_PAYMENT_METHOD_LIST_FAIL = "DELETE_PAYMENT_METHOD_LIST_FAIL"

