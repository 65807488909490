import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { limitList } from "helpers/helpers";

const Paginations = ({ perPageData, data, currentPage, setCurrentPage, isShowingPageLength, paginationDiv, paginationClass, totalCount,isLimit,limit,setLimit}) => {

    //pagination

    const handleClick = (e) => {
        setCurrentPage(Number(e.target.id));
    };

    const pageNumbers = [];
    // for (let i = 1; i <= Math.ceil(data?.length / perPageData); i++) {
    for (let i = 1; i <= Math.ceil(totalCount / perPageData); i++) {
    // for (let i = 1; i <= totalCount; i++) {
        pageNumbers.push(i);
    }
    const handleprevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
    };
    const handlenextPage = (event) => {
        event.preventDefault();
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            setCurrentPage(pageNumbers.length)
        }
    }, [pageNumbers.length, currentPage, setCurrentPage])

    return (
        <React.Fragment>
                {isShowingPageLength && data.length >0 && 
            <Row >
                
                <Col sm={12} md={5} className="d-flex align-items-center">
                    <div className="text-muted dataTables_info">

                    Showing  {(currentPage - 1) * perPageData+1} - {((currentPage - 1) * perPageData) + data.length} of {totalCount} {totalCount === 1?'Result':'Results'}
                    </div>
                    {
                        isLimit &&
                    <div className="mx-2">
 
                    <Input type="select" label="Limit" value={limit} onChange={(e)=>setLimit(e.target.value)}
                >
                {
                    limitList?.map((item,ind)=><option value={item} key={`item.value+${ind}`}>{item}</option>)
                }
                </Input>
                
 </div>
                    }
                </Col>
              
                <Col sm={12} md={7} >
        
                <div className={paginationDiv}>
                    <ul className={paginationClass}>
                        <li className={`page-item ${currentPage <= 1 ? "disabled" : ''}`}>
                            <Link className="page-link" to="#" onClick={() => handleprevPage()}>
                                <i className="mdi mdi-chevron-left"></i>
                            </Link>
                        </li>
                        {(pageNumbers || []).map((item, index) => (
                            <li className={currentPage === item ? "page-item active " : "page-item"} key={index}>
                                <Link className="page-link" to="#" id={item} onClick={(e) => handleClick(e)}>
                                    {item}
                                </Link>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === pageNumbers[pageNumbers.length - 1]? "disabled" : ''}`}>
                            <Link className="page-link" to="#" onClick={(e) => handlenextPage(e)}>
                                <i className="mdi mdi-chevron-right"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
                </Col>
            </Row>
                            }
        </React.Fragment>
    );
}

export default Paginations;