import {
  GET_VENDOR_TYPE,
GET_VENDOR_TYPE_SUCCESS,
GET_VENDOR_TYPE_FAIL,
UPDATE_VENDOR_TYPE,
UPDATE_VENDOR_TYPE_SUCCESS,
UPDATE_VENDOR_TYPE_FAIL,

} from "./actionTypes"

export const getVendorTypeAction = () => ({
  type: GET_VENDOR_TYPE,
})

export const getVendorTypeActionSuccess = tasks => ({
  type: GET_VENDOR_TYPE_SUCCESS,
  payload: tasks,
})

export const getVendorTypeActionFail = error => ({
  type: GET_VENDOR_TYPE_FAIL,
  payload: error,
})



export const updateVendorTypeAction = card => ({
  type: UPDATE_VENDOR_TYPE,
  payload: card,
})

export const updateVendorTypeActionSuccess = card => ({
  type: UPDATE_VENDOR_TYPE_SUCCESS,
  payload: card,
})

export const updateVendorTypeActionFail = error => ({
  type: UPDATE_VENDOR_TYPE_FAIL,
  payload: error,
})
