import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  ADD_NEW_CATEGORY_LIST,
  DELETE_CATEGORY_LIST,
  GET_CATEGORY,
  UPDATE_CATEGORY_LIST,
} from "./actionTypes"
import {
  getCategorySuccess,
  getCategoryFail,
  deleteCategoryListFail,
  deleteCategoryListSuccess,
  updateCategoryListSuccess,
  updateCategoryListFail,
  addNewCategoryFail,
  addNewCategorySuccess,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getCategory,
  deleteKanban,
  addCardData,
  updateCardData,
} from "helpers/fakebackend_helper"

import {
  addNewCategory,
  updateCategory,
  deleteCategoryList,
} from "helpers/backendhelper"

function* fetchCategory({payload:params}) {
  try {
    console.log(params,"params")
    const response = yield call(getCategory,params)
    yield put(getCategorySuccess(response))
  } catch (error) {
    yield put(getCategoryFail(error))
  }
}


function* onAddNewCategoryList({ payload: data }) {
  try {
      const response = yield call(addNewCategory, data)
      yield put(addNewCategorySuccess(response))
      console.log(window.location.pathname,"window.location.pathname=")
      toast.success(`${window.location.pathname=='/sub-category'?"Subcategory":"Category"}  is Added Successfully`, { autoClose: 2000 });
  } catch (error) {
      yield put(addNewCategoryFail(error))
      toast.error(`${window.location.pathname=='/sub-category'?"Subcategory":"Category"}  is Added Failed`, { autoClose: 2000 });
  }
}

function* onUpdateCategoryList({ payload: data }) {
  try {
      const response = yield call(updateCategory, data)
      yield put(updateCategoryListSuccess(response))
      toast.success(`${window.location.pathname=='/sub-category'?"Subcategory":"Category"}  is Updated Successfully`, { autoClose: 2000 });
  } catch (error) {

    console.log(error,"error")
      yield put(updateCategoryListFail(error))
      toast.error(`${window.location.pathname=='/sub-category'?"Subcategory":"Category"}  is Updated Failed`, { autoClose: 2000 });
  }
}



function* onDeleteCategoryList({ payload: data }) {

  try {
      const response = yield call(deleteCategoryList, data)
      yield put(deleteCategoryListSuccess(response))
    
      toast.success(`${window.location.pathname=='/sub-category'?"Subcategory":"Category"} is  Deleted Successfully`, { autoClose: 2000 });
  } catch (error) {
      yield put(deleteCategoryListFail(error))
      console.log(error)
      toast.error(`${window.location.pathname=='/sub-category'?"Subcategory":"Category"} is Deleted Failed`, { autoClose: 2000 });
  }
}






function* categorySaga() {
  yield takeEvery(GET_CATEGORY, fetchCategory)
  yield takeEvery(ADD_NEW_CATEGORY_LIST, onAddNewCategoryList)
  yield takeEvery(UPDATE_CATEGORY_LIST, onUpdateCategoryList)
  yield takeEvery(DELETE_CATEGORY_LIST, onDeleteCategoryList)

}


export default categorySaga
