import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {

  GET_VENDOR_TYPE,
  UPDATE_VENDOR_TYPE,
} from "./actionTypes"
import {
  getVendorTypeActionSuccess,
  getVendorTypeActionFail,
  updateVendorTypeActionSuccess,
  updateVendorTypeActionFail,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import { getVendorType, updateVendorType } from "helpers/backendhelper"

function* fetchVendorType() {
  try {
    const response = yield call(getVendorType)
    yield put(getVendorTypeActionSuccess(response))
  } catch (error) {
    yield put(getVendorTypeActionFail(error))
  }
}



function* onUpdateVendorType({ payload:{id,data} }) {
  try {
    const response = yield call(updateVendorType, {id,data})
    yield put(updateVendorTypeActionSuccess(response))
    toast.success("Vendor Type is Update Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateVendorTypeActionFail(error))
    toast.error("Vendor Type is Update Failed", { autoClose: 2000 });
  }
}

function* vendorTypeSaga() {
  yield takeEvery(GET_VENDOR_TYPE, fetchVendorType)
  yield takeEvery(UPDATE_VENDOR_TYPE, onUpdateVendorType)

}

export default vendorTypeSaga
