import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  ADD_NEW_PAYMENT_METHOD_LIST,
  DELETE_PAYMENT_METHOD_LIST,
  GET_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_LIST,
} from "./actionTypes"
import {
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  deletePaymentMethodListFail,
  deletePaymentMethodListSuccess,
  updatePaymentMethodListSuccess,
  updatePaymentMethodListFail,
  addNewPaymentMethodFail,
  addNewPaymentMethodSuccess,
} from "./actions"

import { toast } from "react-toastify"


import {
  getPaymentMethod,
  addNewPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethodList,
} from "helpers/backendhelper"

function* fetchPaymentMethod() {
  try {
    const response = yield call(getPaymentMethod)
    yield put(getPaymentMethodSuccess(response))
  } catch (error) {
    yield put(getPaymentMethodFail(error))
  }
}


function* onAddNewPaymentMethodList({ payload: data }) {
  try {
      const response = yield call(addNewPaymentMethod, data)
      yield put(addNewPaymentMethodSuccess(response))
      toast.success("Payment Method is Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(addNewPaymentMethodFail(error))
      toast.error("Payment Method is Added Failed", { autoClose: 2000 });
  }
}

function* onUpdatePaymentMethodList({ payload: {id,data} }) {
  try {
      const response = yield call(updatePaymentMethod, {id,data})
      yield put(updatePaymentMethodListSuccess(response))
      toast.success("Payment Method is Updated Successfully", { autoClose: 2000 });
  } catch (error) {

    console.log(error,"error")
      yield put(updatePaymentMethodListFail(error))
      toast.error("Payment Method is Updated Failed", { autoClose: 2000 });
  }
}



function* onDeletePaymentMethodList({ payload: data }) {

  try {
      const response = yield call(deletePaymentMethodList, data)
      yield put(deletePaymentMethodListSuccess(response))
      toast.success("Payment Method is Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(deletePaymentMethodListFail(error))
      console.log(error)
      toast.error("Payment Method Deleted Failed", { autoClose: 2000 });
  }
}






function* paymentMethodSaga() {
  yield takeEvery(GET_PAYMENT_METHOD, fetchPaymentMethod)
  yield takeEvery(ADD_NEW_PAYMENT_METHOD_LIST, onAddNewPaymentMethodList)
  yield takeEvery(UPDATE_PAYMENT_METHOD_LIST, onUpdatePaymentMethodList)
  yield takeEvery(DELETE_PAYMENT_METHOD_LIST, onDeletePaymentMethodList)

}


export default paymentMethodSaga
