import { useEffect, useCallback } from 'react';

export default function useEventBus(eventName, callback) {
    // Memoize the event handler to maintain reference equality
    const eventHandler = useCallback((e) => {
        callback?.(e.detail);
    }, [callback]);

    // Subscribe to event when component mounts
    useEffect(() => {
        if (callback) {
            document.addEventListener(eventName, eventHandler);

            // Cleanup subscription when component unmounts
            return () => {
                document.removeEventListener(eventName, eventHandler);
            };
        }
    }, [eventName, eventHandler, callback]);

    // Dispatch event function
    const dispatch = useCallback((data = null) => {
        document.dispatchEvent(
            new CustomEvent(eventName, {
                detail: data
            })
        );
    }, [eventName]);

    return [ dispatch ];
}