import {
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAIL,
  DELETE_PAYMENT_METHOD_LIST_SUCCESS,
  DELETE_PAYMENT_METHOD_LIST_FAIL,
  UPDATE_PAYMENT_METHOD_LIST_SUCCESS,
  UPDATE_PAYMENT_METHOD_LIST_FAIL,
  ADD_NEW_PAYMENT_METHOD_LIST_FAIL,
  ADD_NEW_PAYMENT_METHOD_LIST_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  paymentMethods: [],
  error: {},
  loading : true
}

const paymentMethodReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload,
        loading : true
      }

    case GET_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      
      case ADD_NEW_PAYMENT_METHOD_LIST_SUCCESS:
        return {
            ...state,
            // paymentMethods: [action.payload, ...state.paymentMethods],
        };

    case ADD_NEW_PAYMENT_METHOD_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case UPDATE_PAYMENT_METHOD_LIST_SUCCESS:
      console.log(state,"ststew")
        return {
            ...state,
            paymentMethods: state.paymentMethods.map(category =>
              category.id.toString() === action.payload.id.toString()
                    ? { category, ...action.payload }
                    : category
            ),
        };

    case UPDATE_PAYMENT_METHOD_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case DELETE_PAYMENT_METHOD_LIST_SUCCESS:
        return {
            ...state,
            paymentMethods: state.paymentMethods.filter(
                category => category.id.toString() !== action.payload.toString()
            ),
        };

    case DELETE_PAYMENT_METHOD_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    default:
      return state
  }
}

export default paymentMethodReducer
