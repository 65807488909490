import {
  GET_PAYMENT_TERM,
  GET_PAYMENT_TERM_SUCCESS,
  GET_PAYMENT_TERM_FAIL,
  DELETE_PAYMENT_TERM_LIST,
  DELETE_PAYMENT_TERM_LIST_SUCCESS,
  DELETE_PAYMENT_TERM_LIST_FAIL,
  UPDATE_PAYMENT_TERM_LIST_FAIL,
  UPDATE_PAYMENT_TERM_LIST_SUCCESS,
  UPDATE_PAYMENT_TERM_LIST,
  ADD_NEW_PAYMENT_TERM_LIST_FAIL,
  ADD_NEW_PAYMENT_TERM_LIST_SUCCESS,
  ADD_NEW_PAYMENT_TERM_LIST
} from "./actionTypes"

export const getPaymentTerm = () => ({
  type: GET_PAYMENT_TERM,
})

export const getPaymentTermSuccess = payment => ({
  type: GET_PAYMENT_TERM_SUCCESS,
  payload: payment,
})

export const getPaymentTermFail = error => ({
  type: GET_PAYMENT_TERM_FAIL,
  payload: error,
})



export const addNewPaymentTermList = data => ({
  type: ADD_NEW_PAYMENT_TERM_LIST,
  payload: data,
})


export const addNewPaymentTermSuccess = payment => ({
  type: ADD_NEW_PAYMENT_TERM_LIST_SUCCESS,
  payload: payment,
})

export const addNewPaymentTermFail = error => ({
  type: ADD_NEW_PAYMENT_TERM_LIST_FAIL,
  payload: error,
})


export const updatePaymentTermList = payment => ({
  type: UPDATE_PAYMENT_TERM_LIST,
  payload: payment,
})

export const updatePaymentTermListSuccess = payment => ({
  type: UPDATE_PAYMENT_TERM_LIST_SUCCESS,
  payload: payment,
})

export const updatePaymentTermListFail = error => ({
  type: UPDATE_PAYMENT_TERM_LIST_FAIL,
  payload: error,
})

export const deletePaymentTermList = data => ({
  type: DELETE_PAYMENT_TERM_LIST,
  payload: data,
})


export const deletePaymentTermListSuccess = data => ({
  type: DELETE_PAYMENT_TERM_LIST_SUCCESS,
  payload: data,
})

export const deletePaymentTermListFail = error => ({
  type: DELETE_PAYMENT_TERM_LIST_FAIL,
  payload: error,
})