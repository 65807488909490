import {
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAIL,
  DELETE_PAYMENT_METHOD_LIST,
  DELETE_PAYMENT_METHOD_LIST_SUCCESS,
  DELETE_PAYMENT_METHOD_LIST_FAIL,
  UPDATE_PAYMENT_METHOD_LIST_FAIL,
  UPDATE_PAYMENT_METHOD_LIST_SUCCESS,
  UPDATE_PAYMENT_METHOD_LIST,
  ADD_NEW_PAYMENT_METHOD_LIST_FAIL,
  ADD_NEW_PAYMENT_METHOD_LIST_SUCCESS,
  ADD_NEW_PAYMENT_METHOD_LIST
} from "./actionTypes"

export const getPaymentMethod = () => ({
  type: GET_PAYMENT_METHOD,
})

export const getPaymentMethodSuccess = payment => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  payload: payment,
})

export const getPaymentMethodFail = error => ({
  type: GET_PAYMENT_METHOD_FAIL,
  payload: error,
})



export const addNewPaymentMethodList = data => ({
  type: ADD_NEW_PAYMENT_METHOD_LIST,
  payload: data,
})


export const addNewPaymentMethodSuccess = payment => ({
  type: ADD_NEW_PAYMENT_METHOD_LIST_SUCCESS,
  payload: payment,
})

export const addNewPaymentMethodFail = error => ({
  type: ADD_NEW_PAYMENT_METHOD_LIST_FAIL,
  payload: error,
})


export const updatePaymentMethodList = payment => ({
  type: UPDATE_PAYMENT_METHOD_LIST,
  payload: payment,
})

export const updatePaymentMethodListSuccess = payment => ({
  type: UPDATE_PAYMENT_METHOD_LIST_SUCCESS,
  payload: payment,
})

export const updatePaymentMethodListFail = error => ({
  type: UPDATE_PAYMENT_METHOD_LIST_FAIL,
  payload: error,
})

export const deletePaymentMethodList = data => ({
  type: DELETE_PAYMENT_METHOD_LIST,
  payload: data,
})


export const deletePaymentMethodListSuccess = data => ({
  type: DELETE_PAYMENT_METHOD_LIST_SUCCESS,
  payload: data,
})

export const deletePaymentMethodListFail = error => ({
  type: DELETE_PAYMENT_METHOD_LIST_FAIL,
  payload: error,
})