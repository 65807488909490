import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  ADD_NEW_PAYMENT_TERM_LIST,
  DELETE_PAYMENT_TERM_LIST,
  GET_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM_LIST,
} from "./actionTypes"
import {
  getPaymentTermSuccess,
  getPaymentTermFail,
  deletePaymentTermListFail,
  deletePaymentTermListSuccess,
  updatePaymentTermListSuccess,
  updatePaymentTermListFail,
  addNewPaymentTermFail,
  addNewPaymentTermSuccess,
} from "./actions"

import { toast } from "react-toastify"


import {
  getPaymentTerm,
  addNewPaymentTerm,
  updatePaymentTerm,
  deletePaymentTermList,
} from "helpers/backendhelper"

function* fetchPaymentTerm() {
  try {
    const response = yield call(getPaymentTerm)
    yield put(getPaymentTermSuccess(response))
  } catch (error) {
    yield put(getPaymentTermFail(error))
  }
}


function* onAddNewPaymentTermList({ payload: data }) {
  try {
      const response = yield call(addNewPaymentTerm, data)
      yield put(addNewPaymentTermSuccess(response))
      toast.success("Payment Term is Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(addNewPaymentTermFail(error))
      toast.error("Payment Term is Added Failed", { autoClose: 2000 });
  }
}

function* onUpdatePaymentTermList({ payload: {id,data} }) {
  try {
      const response = yield call(updatePaymentTerm, {id,data})
      yield put(updatePaymentTermListSuccess(response))
      toast.success("Payment Term is Updated Successfully", { autoClose: 2000 });
  } catch (error) {

    console.log(error,"error")
      yield put(updatePaymentTermListFail(error))
      toast.error("Payment Term is Updated Failed", { autoClose: 2000 });
  }
}



function* onDeletePaymentTermList({ payload: data }) {

  try {
      const response = yield call(deletePaymentTermList, data)
      yield put(deletePaymentTermListSuccess(response))
      toast.success("Payment Term is Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(deletePaymentTermListFail(error))
      console.log(error)
      toast.error("PaymentTerm Deleted Failed", { autoClose: 2000 });
  }
}






function* paymentTermSaga() {
  yield takeEvery(GET_PAYMENT_TERM, fetchPaymentTerm)
  yield takeEvery(ADD_NEW_PAYMENT_TERM_LIST, onAddNewPaymentTermList)
  yield takeEvery(UPDATE_PAYMENT_TERM_LIST, onUpdatePaymentTermList)
  yield takeEvery(DELETE_PAYMENT_TERM_LIST, onDeletePaymentTermList)

}


export default paymentTermSaga
