import {
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  DELETE_CATEGORY_LIST,
  DELETE_CATEGORY_LIST_SUCCESS,
  DELETE_CATEGORY_LIST_FAIL,
  UPDATE_CATEGORY_LIST_FAIL,
  UPDATE_CATEGORY_LIST_SUCCESS,
  UPDATE_CATEGORY_LIST,
  ADD_NEW_CATEGORY_LIST_FAIL,
  ADD_NEW_CATEGORY_LIST_SUCCESS,
  ADD_NEW_CATEGORY_LIST
} from "./actionTypes"

export const getCategory = (payload) => ({
  type: GET_CATEGORY,
  payload: payload,
})

export const getCategorySuccess = category => ({
  type: GET_CATEGORY_SUCCESS,
  payload: category,
})

export const getCategoryFail = error => ({
  type: GET_CATEGORY_FAIL,
  payload: error,
})



export const addNewCategoryList = data => ({
  type: ADD_NEW_CATEGORY_LIST,
  payload: data,
})


export const addNewCategorySuccess = category => ({
  type: ADD_NEW_CATEGORY_LIST_SUCCESS,
  payload: category,
})

export const addNewCategoryFail = error => ({
  type: ADD_NEW_CATEGORY_LIST_FAIL,
  payload: error,
})


export const updateCategoryList = category => ({
  type: UPDATE_CATEGORY_LIST,
  payload: category,
})

export const updateCategoryListSuccess = category => ({
  type: UPDATE_CATEGORY_LIST_SUCCESS,
  payload: category,
})

export const updateCategoryListFail = error => ({
  type: UPDATE_CATEGORY_LIST_FAIL,
  payload: error,
})

export const deleteCategoryList = data => ({
  type: DELETE_CATEGORY_LIST,
  payload: data,
})


export const deleteCategoryListSuccess = data => ({
  type: DELETE_CATEGORY_LIST_SUCCESS,
  payload: data,
})

export const deleteCategoryListFail = error => ({
  type: DELETE_CATEGORY_LIST_FAIL,
  payload: error,
})