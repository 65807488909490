/* CATEGORY */
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

export const ADD_NEW_CATEGORY_LIST = "ADD_NEW_CATEGORY_LIST"
export const ADD_NEW_CATEGORY_LIST_SUCCESS = "ADD_NEW_CATEGORY_LIST_SUCCESS"
export const ADD_NEW_CATEGORY_LIST_FAIL = "ADD_NEW_CATEGORY_LIST_FAIL"

export const UPDATE_CATEGORY_LIST = "UPDATE_CATEGORY_LIST"
export const UPDATE_CATEGORY_LIST_SUCCESS = "UPDATE_CATEGORY_LIST_SUCCESS"
export const UPDATE_CATEGORY_LIST_FAIL = "UPDATE_CATEGORY_LIST_FAIL"

export const DELETE_CATEGORY_LIST = "DELETE_CATEGORY_LIST"
export const DELETE_CATEGORY_LIST_SUCCESS = "DELETE_CATEGORY_LIST_SUCCESS"
export const DELETE_CATEGORY_LIST_FAIL = "DELETE_CATEGORY_LIST_FAIL"

