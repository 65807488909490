import {
  GET_VENDOR_TYPE_SUCCESS,
  GET_VENDOR_TYPE_FAIL,
  UPDATE_VENDOR_TYPE_SUCCESS,
  UPDATE_VENDOR_TYPE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  vendorTypeList: [],
  error: {},
  loading : true
}

const vendorTypeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENDOR_TYPE_SUCCESS:
      return {
        ...state,
        vendorTypeList: action.payload,
        loading : true
      }

    case GET_VENDOR_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case UPDATE_VENDOR_TYPE_SUCCESS:
      return {
        ...state,
        vendorTypeList: state.vendorTypeList.map(vendorType =>
          vendorType.id.toString() === action.payload.id.toString()
                ? { vendorType, ...action.payload }
                : vendorType
        ),
      }

    case UPDATE_VENDOR_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    default:
      return state
  }
}

export default vendorTypeReducer
