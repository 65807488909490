import React, { useState } from 'react';

const ImagePopup = ({ src, alt, thumbnailClassName, styles }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgError, setImgError] = useState(false);

  const popupStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    opacity: isOpen ? 1 : 0,
    pointerEvents: isOpen ? 'auto' : 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999999,
    cursor: 'pointer',
    transition: 'opacity 0.3s ease-in-out'
  };

  const popupImageStyle = {
    maxWidth: '90%',
    maxHeight: '90%',
    objectFit: 'contain',
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'scale(1)' : 'scale(0.9)',
    transition: 'all 0.3s ease-in-out'
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleImageError = () => {
    setImgError(true);
  };

  const placeholderStyle = {
    width: '100%',
    height: '100%',
    minHeight: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8f9fa',
    border: '1px solid #dee2e6',
    borderRadius: '4px',
    color: '#6c757d'
  };

  if (imgError) {
    return (
      <div style={placeholderStyle}>
        <i className="mdi mdi-image-off" style={{ fontSize: '24px' }} />
      </div>
    );
  }
  

  return (
    <>
      <img 
        src={src} 
        alt={alt} 
        onClick={handleOpen}
        onError={handleImageError}
        style={{ width: '100%', ...styles, cursor: 'pointer' }}
        className={thumbnailClassName}
      />
      <div 
        style={popupStyle} 
        onClick={handleClose}
      >
        <img 
          src={src} 
          alt={alt} 
          style={popupImageStyle}
          onError={handleImageError}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </>
  );
};

export default ImagePopup;
