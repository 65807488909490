/* PAYMENT TERM */
export const GET_PAYMENT_TERM = "GET_PAYMENT_TERM"
export const GET_PAYMENT_TERM_SUCCESS = "GET_PAYMENT_TERM_SUCCESS"
export const GET_PAYMENT_TERM_FAIL = "GET_PAYMENT_TERM_FAIL"

export const ADD_NEW_PAYMENT_TERM_LIST = "ADD_NEW_PAYMENT_TERM_LIST"
export const ADD_NEW_PAYMENT_TERM_LIST_SUCCESS = "ADD_NEW_PAYMENT_TERM_LIST_SUCCESS"
export const ADD_NEW_PAYMENT_TERM_LIST_FAIL = "ADD_NEW_PAYMENT_TERM_LIST_FAIL"

export const UPDATE_PAYMENT_TERM_LIST = "UPDATE_PAYMENT_TERM_LIST"
export const UPDATE_PAYMENT_TERM_LIST_SUCCESS = "UPDATE_PAYMENT_TERM_LIST_SUCCESS"
export const UPDATE_PAYMENT_TERM_LIST_FAIL = "UPDATE_PAYMENT_TERM_LIST_FAIL"

export const DELETE_PAYMENT_TERM_LIST = "DELETE_PAYMENT_TERM_LIST"
export const DELETE_PAYMENT_TERM_LIST_SUCCESS = "DELETE_PAYMENT_TERM_LIST_SUCCESS"
export const DELETE_PAYMENT_TERM_LIST_FAIL = "DELETE_PAYMENT_TERM_LIST_FAIL"

